import React from 'react'
import {
  Button,
  Center,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react';

function ConfirmDialog({ dialogImageUrl, ConfirmText, BtnConfirmText, onConfirm, color, width }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef(null);

  return (
    <>
      <Button
        py="6"
        borderRadius="full"
        colorScheme={color}
        onClick={onOpen}
        width={width}
      >
        {BtnConfirmText}
      </Button>

      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent pt="10" pb="5" px="5" mx="4">
          <ModalBody as={Center} flexDirection="column" mb="3">
            <Image src={dialogImageUrl} mb="6" />
            <Text fontSize="lg" textAlign="center">
              {ConfirmText}
            </Text>
          </ModalBody>
          <ModalFooter
            as={Flex}
            rowGap="3"
            columnGap="5"
            mt="3"
            flexDirection={{
              base: 'column-reverse',
              sm: 'row',
            }}
          >
            <Button
              py="6"
              w="full"
              borderRadius="full"
              colorScheme="gray"
              onClick={onClose}
            >
              Batal
            </Button>
            <Button
              py="6"
              w="full"
              borderRadius="full"
              colorScheme={color}
              onClick={() => { onConfirm(); onClose(); }}

            >
              {BtnConfirmText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ConfirmDialog