import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  Circle,
  Flex,
  HStack,
  IconButton,
  Select,
  Text,
} from '@chakra-ui/react';
import { setPageIndex, setSizePage } from 'reducer/modalReducer';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export default function Pagination({
  pageSize,
  pageCount,
  setPageSize,
  pageIndex,
  pageOptions,
  canNextPage,
  canPreviousPage,
  nextPage,
  previousPage,
  gotoPage,
}) {
  const selectValue = [10, 20, 30, 40, 50];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageIndex(pageIndex));
  },[pageIndex]);
  return (
    <Flex justifyContent="space-between" alignItems="center" py="3" px="5">
      <HStack>
        <Text>Show:</Text>
        <Select
          w={32}
          value={pageSize}
          onChange={e => {setPageSize(Number(e.target.value)); dispatch(setSizePage(Number(e.target.value)))}}
        >
          {selectValue.map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
      </HStack>

      <HStack>
        <IconButton
          variant="outline"
          colorScheme="teal"
          icon={<ArrowLeftIcon h="3" w="3" />}
          borderRadius="100%"
          isDisabled={!canPreviousPage}
          onClick={() => gotoPage(0)}
        />
        <IconButton
          variant="outline"
          colorScheme="teal"
          icon={<ChevronLeftIcon h="6" w="6" />}
          borderRadius="100%"
          isDisabled={!canPreviousPage}
          onClick={() => previousPage()}
        />
        <Circle
          bgColor="teal.100"
          color="teal.900"
          px="3"
          py="1"
          fontWeight="semibold"
        >
          {pageIndex + 1}
        </Circle>
        <Text>of</Text>
        <Circle
          bgColor="gray.100"
          color="gray.500"
          px="3"
          py="1"
          fontWeight="semibold"
        >
          {pageOptions?.length}
        </Circle>
        <IconButton
          variant="outline"
          colorScheme="teal"
          icon={<ChevronRightIcon h="6" w="6" />}
          borderRadius="100%"
          isDisabled={!canNextPage}
          onClick={() => nextPage()}
        />
        <IconButton
          variant="outline"
          colorScheme="teal"
          icon={<ArrowRightIcon h="3" w="3" />}
          borderRadius="100%"
          isDisabled={!canNextPage}
          onClick={() => gotoPage(pageCount - 1)}
        />
      </HStack>
    </Flex>
  );
}
