import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import ContentLayout from 'components/layout/ContentLayout';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// import { toast } from 'react-toastify';
import { createAdmin } from 'utils/axios.services';
import { EmailIcon } from '@chakra-ui/icons';
import { FaIdCardAlt } from 'react-icons/fa';
import { setIsOpen } from 'reducer/modalReducer';
import ResponseDialog from 'components/common/ResponseDialog';
import ConfirmDialog from 'components/common/ConfirmDialog';

export default function TambahAdmin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [responseType, setResponseType] = useState();

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  async function onSubmit() {
    const data = getValues();
    await createAdmin(data.firstName, data.lastName, data.email)
      .then(() => {
        navigate('/manajemen-admin/tambah-admin');
        // toast.success('Data berhasil ditambahkan');
        dispatch(setIsOpen(true));
        setResponseType('success');
        setValue('firstName', '');
        setValue('lastName', '');
        setValue('email', '');
      })
      .catch(() => {
        // toast.error('Gagal menambah data');
        dispatch(setIsOpen(true));
        setResponseType('fail');
      });
  }

  const routes = [
    {
      name: 'Manajemen Admin',
      url: '/manajemen-admin',
    },
    {
      name: 'Tambah Admin',
    },
  ];

  return (
    <ContentLayout routes={routes}>
      <Heading fontSize="28" mb="8">
        Tambah Admin
      </Heading>
      <Box
        bgColor="white"
        borderRadius="1rem"
        p="5"
        w={{
          base: 'full',
          lg: '700px',
        }}
      >
        <Flex
          as="form"
          flexDirection="column"
          gap="6"
        >
          <FormControl isInvalid={errors.firstName}>
            <FormLabel
              color="dietela.primary"
              fontFamily="roboto"
              fontWeight="400px"
            >
              Nama Depan
            </FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<FaIdCardAlt color="teal" />}
              />
              <Input
                id="firstName"
                placeholder="Masukkan Nama Depan"
                {...register('firstName', {
                  required: 'Nama Depan wajib diisi',
                })}
                color="dietela.primary"
                fontFamily="roboto"
                fontWeight="400px"
                focusBorderColor="teal"
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.firstName && errors.firstName.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.lastName}>
            <FormLabel
              color="dietela.primary"
              fontFamily="roboto"
              fontWeight="400px"
            >
              Nama Belakang
            </FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<FaIdCardAlt color="teal" />}
              />
              <Input
                id="lastName"
                placeholder="Masukkan Nama Belakang"
                {...register('lastName', {
                  required: 'Nama Belakang wajib diisi',
                })}
                color="dietela.primary"
                fontFamily="roboto"
                fontWeight="400px"
                focusBorderColor="teal"
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.lastName && errors.lastName.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.email}>
            <FormLabel
              color="dietela.primary"
              fontFamily="roboto"
              fontWeight="400px"
            >
              Email
            </FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<EmailIcon color="teal" />}
              />
              <Input
                id="email"
                type="email"
                placeholder="Masukkan Email"
                {...register('email', {
                  required: 'Email wajib diisi',
                })}
                color="dietela.primary"
                fontFamily="roboto"
                fontWeight="400px"
                focusBorderColor="teal"
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <Flex
            rowGap="3"
            columnGap="5"
            mt="3"
            flexDirection={{
              base: 'column-reverse',
              sm: 'row',
            }}
          >
            <Button
              py="6"
              borderRadius="full"
              colorScheme="gray"
              type="reset"
              w="95px"
            >
              Batal
            </Button>
            { responseType === 'success'
              ? (
                <ResponseDialog
                  dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/berhasil_simpandata.png"
                  DialogText="Data berhasil ditambahkan!"
                  BtnDialogText="Selesai"
                />
              ) : (
                <ResponseDialog
                  dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/gagal_simpandata.svg"
                  DialogText="Gagal menyimpan data"
                  BtnDialogText="Selesai"
                />
              )}

            <ConfirmDialog
              dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/konfirmasi_simpan.png"
              ConfirmText="Apakah Anda yakin ingin menambah admin?"
              BtnConfirmText="Simpan"
              onConfirm={onSubmit}
              color="teal"
            />
            {/* <Button
                py="6"
                w="full"
                borderRadius="full"
                colorScheme="teal"
                type="submit"
                isLoading={isSubmitting}
                fontFamily="roboto"
                fontSize="14px"
                fontWeight="400px"
              >
                Simpan
              </Button> */}
          </Flex>
        </Flex>
      </Box>
    </ContentLayout>
  );
}
