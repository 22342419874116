import React, { useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Box, Flex, Heading, Select } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function MultiPieChart({ datasets }) {
  const timeFrame = useSelector((state) => state.modal.timeFrame);
  const [value, setValue] = useState(datasets.filterBy[0].select);
  const [dataset, setDataset] = useState(datasets.filterBy[0]);

  const handleChange = event => {
    setValue(event.target.value);
    filterChart(event.target.value);
  };

  const filterChart = filterValue => {
    const filteredDataset = datasets.filterBy.find(
      dataset => dataset.select === filterValue
    );
    setDataset(filteredDataset);
  };

  useEffect(() => {
    if (timeFrame !== '') {
      setValue(timeFrame);
      const filteredDataset = datasets.filterBy.find(
        dataset => dataset.select === timeFrame
      );
      if (filteredDataset) {
        setDataset(filteredDataset);
      }
    }
  }, [timeFrame])

  const backgroundColor = [
    '#F6D7A7',
    '#259E86',
    '#87AAAA',
    '#F6EABE',
    '#C8E3D4',
    '#F2B227',
    '#5D965E',
  ];

  const dataDisease = {
    labels: datasets.labels,
    datasets: [
      {
        label: dataset.disease.label,
        backgroundColor: backgroundColor,
        borderWidth: 0,
        data: dataset.disease.value,
      },
    ],
  };

  const dataWeight = {
    labels: datasets.labels,
    datasets: [
      {
        label: dataset.weight.label,
        backgroundColor: backgroundColor,
        borderWidth: 0,
        data: dataset.weight.value,
      },
    ],
  };

  const dataPregnancy = {
    labels: datasets.labels,
    datasets: [
      {
        label: dataset.pregnancy.label,
        backgroundColor: backgroundColor,
        borderWidth: 0,
        data: dataset.pregnancy.value,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        labels: {
          font: { family: 'Montserrat', size: '14' },
          color: '#000',
          boxWidth: 14,
        },
      },
      datalabels: {
        formatter: value => {
          return value;
        },
        color: '#000',
        font: { family: 'Montserrat', weight: '500', size: '14' },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.dataset.label + ': ' + context.parsed;
          },
        },
      },
    },
  };

  return (
    <Box
      bg="white"
      overflow="hidden"
      borderRadius="8px"
      border="1px solid"
      borderColor="dietela.primary"
      overflowX="auto"
    >
      <Box bg="dietela.primary" p="5">
        <Heading size="sm" color="white">
          {datasets.title}
        </Heading>
      </Box>
      <Flex flexDirection="column" p="5">
        <Select
          className="timeframe-selector"
          value={value}
          onChange={handleChange}
          mb={10}
          width={'50%'}
          bg={'#F8F9FA'}
          variant={'filled'}
          border={'1px solid'}
          borderColor={'#DDDDDD'}
          _focus={{
            border: '2px solid',
            borderColor: 'dietela.primary',
            boxShadow: 'none',
          }}
        >
          {datasets.filter.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
        {datasets.question && (
          <Heading fontWeight={'400'} size={'sm'}>
            {datasets.question}
          </Heading>
        )}
        <Flex flexDirection="column" alignItems="center">
          <Flex
            flexWrap="wrap"
            columnGap={20}
            rowGap={10}
            mb="10"
            justifyContent="center"
          >
            <Flex w="250px" flexDirection="column" alignItems="center" gap="5">
              <Heading size="sm">{dataset.disease.label}</Heading>
              <Pie data={dataDisease} options={options} />
            </Flex>
            <Flex w="250px" flexDirection="column" alignItems="center" gap="5">
              <Heading size="sm">{dataset.weight.label}</Heading>
              <Pie data={dataWeight} options={options} />
            </Flex>
            <Flex w="250px" flexDirection="column" alignItems="center" gap="5">
              <Heading size="sm">{dataset.pregnancy.label}</Heading>
              <Pie data={dataPregnancy} options={options} />
            </Flex>
          </Flex>
          <Flex
            rowGap="3"
            columnGap="8"
            flexDirection={{
              base: 'column',
              md: 'row',
            }}
          >
            {datasets.labels.map((item, index) => (
              <Flex key={index} gap="3">
                <Box minW="20px" h="20px" bg={backgroundColor[index]} />
                <Heading fontSize={'14px'} fontWeight="400">
                  {item}
                </Heading>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

export default MultiPieChart;
