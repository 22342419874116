import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Center,
  Box,
  Flex,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';

import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { authLogin } from 'utils/axios.services';

export default function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState(false);

  function handleChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  async function handleLogin() {
    await authLogin(formData.email, formData.password)
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        if (
          typeof localStorage.getItem('token') !== 'undefined' &&
          localStorage.getItem('token') !== null
        ) {
          navigate('/dashboard');
        }
      });
  }

  return (
    <Flex flexDirection="column" w="full" px="8" gap="28">
      <Heading fontSize={'3xl'} textAlign="center" color={'dietela.primary'}>
        Selamat datang!
      </Heading>
      <Center h="full" w="full">
        <Flex flexDirection="column" w="md" gap="5">
          {error ? (
            <Alert status="error" variant="solid" borderRadius="5">
              <AlertIcon />
              <AlertDescription>
                Ada kesalahan pada email atau password!
              </AlertDescription>
            </Alert>
          ) : null}
          <FormControl id="email">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              placeholder="Masukkan email"
              size="lg"
              required
              _focus={{ borderColor: 'dietela.primary' }}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Kata Sandi</FormLabel>
            <Input
              type="password"
              name="password"
              placeholder="Masukkan password"
              size="lg"
              required
              _focus={{ borderColor: 'dietela.primary' }}
              onChange={handleChange}
            />
          </FormControl>
          <Box w="full" textAlign="right">
            <Link
              as={RouterLink}
              to="/forgot-password"
              color={'dietela.primary'}
            >
              Lupa kata sandi?
            </Link>
          </Box>
          <Button
            colorScheme="teal"
            variant={'solid'}
            size="lg"
            borderRadius={'full'}
            onClick={() => {
              handleLogin();
            }}
          >
            Masuk
          </Button>
        </Flex>
      </Center>
    </Flex>
  );
}
