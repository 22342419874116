import { extendTheme } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const colors = {
  dietela: {
    primary: '#008C81',
    secondary: '#F1E456',
    background: '#FFFFFF',
    'primary-variant': '#14A68B',
    'secondary-variant': '#F4CC46',
    'background-primary': '#168C8080',
    'background-secondary': '#F8EDB4',
    'background-tertiary': '#008C811A',
  },
  gradient: {
    top: '#008C8140',
    bottom: '#F4CC460D',
  },
  text: {
    brown: '#534242',
  },
  progress: {
    red: '#FF3C00',
    yellow: '#F4CC46',
    green: '#39A65F',
  },
};

const fonts = {
  heading: '\'Montserrat\', sans-serif',
  body: '\'Roboto\', sans-serif',
};

const theme = extendTheme({
  config,
  colors,
  fonts,
  components: {
    Steps,
  },
});

export const customBackground = color => {
  document.body.style.background = color;
};

export default theme;
