import React, { useContext, useEffect } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  useColorModeValue,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
} from '@chakra-ui/react';
import { FiMenu, FiChevronDown } from 'react-icons/fi';

import logoDietela from '../../../assets/image/Logo_dietela.png';
import { UserContext } from 'App';
import { getAdmin } from 'utils/axios.services';
import { useNavigate } from 'react-router-dom';

export default function NavigationBar({ onOpen, contentSidebar, ...rest }) {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    getAdmin().then(res => {
      setUser(res.data.data);
    });
  }, []);

  return (
    <Flex
      px={{ base: 4, md: 8 }}
      height="20"
      alignItems="center"
      bg={'white'}
      borderBottomWidth="1px"
      borderBottomColor={'gray.200'}
      justifyContent={{
        base: 'space-between',
        md: contentSidebar ? 'flex-end' : 'space-between',
      }}
      {...rest}
    >
      {contentSidebar && (
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />
      )}

      <Image
        display={{ base: 'flex', md: contentSidebar ? 'none' : 'flex' }}
        src={logoDietela}
        h="30px"
        alt="Logo Dietela"
      />

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Text display={{ base: 'none', md: 'flex' }} fontSize="sm">
                  {user?.first_name} {user?.last_name}
                </Text>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
                <Avatar
                  size={'sm'}
                  src={
                    'https://images.unsplash.com/photo-1585358682246-23acb1561f6b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=462&q=80'
                  }
                />
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem
                onClick={() => {
                  navigate('/edit-profile');
                }}
              >
                Lihat Profil
              </MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  localStorage.removeItem('token');
                  localStorage.removeItem('id');
                  localStorage.removeItem('access');
                  localStorage.removeItem('exp');
                  window.location.reload();
                }}
              >
                <Text color="red">Log out</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
}
