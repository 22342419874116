import {
  Box,
  Button,
  Center,
  CircularProgress,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';
import CardResult from 'components/common/CardResult';
import React, { useEffect, useState } from 'react';
import ContentLayout from 'components/layout/ContentLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { getDetailUser, deleteUser } from 'utils/axios.services';
import { useDispatch } from 'react-redux';
import { setIsOpen } from 'reducer/modalReducer';
import ConfirmDialog from 'components/common/ConfirmDialog';

export default function DetailUser() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState([]);
  const params = useParams();
  const userId = params.userId;
  const dispatch = useDispatch();
  const [setResponseType] = useState();

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const { data: response } = await getDetailUser(userId);
      setUser(response.user[0].data);
      setIsLoading(false);
    }
    getData();
  }, []);

  const routes = [
    {
      name: 'Manajemen User',
      url: '/manajemen-user',
    },
    {
      name: 'Detail User',
    },
  ];

  async function handleDelete() {
    await deleteUser(userId)
      .then(() => {
        navigate('/manajemen-user');
        dispatch(setIsOpen(true));
      })
      .catch(() => {
        dispatch(setIsOpen(true));
        setResponseType('fail');
      });
  };

  return (
    <ContentLayout routes={routes}>
      <Heading fontSize="28" mb="8">
        Detail User
      </Heading>

      {isLoading ? (
        <Center>
          <CircularProgress isIndeterminate color="dietela.primary" />
        </Center>
      ) : (
        <Box>
          <Box>
            <Text mb="4" fontWeight="500" fontSize="xl">
              Data Pribadi
            </Text>
            <Box>
              {user.map(
                item =>
                  item.section === 'detail-user' &&
                  item.data.map(data => (
                    <Flex key={data.id} flexDirection="column" gap="1" mb="4">
                      <Text color="dietela.primary">{data.title}</Text>
                      {data.title === 'Result' ? (
                        <Flex gap="7"
                          alignItems="center"
                          mt="2"
                        >
                          <Button
                            p="6"
                            colorScheme="teal"
                            borderRadius="full"
                            w="fit-content"
                            onClick={() =>
                              navigate(
                                `/manajemen-user/detail-user/${userId}/detail-result`
                              )
                            }
                          >
                            {data.cta}
                          </Button>

                          {localStorage.getItem('access') === 1 || localStorage.getItem('access') === '1'
                            ? (
                              <ConfirmDialog
                                dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/konfirmasi_delete.png"
                                ConfirmText={'Anda yakin ingin hapus data?'}
                                BtnConfirmText="Hapus Result"
                                onConfirm={handleDelete}
                                color="red"
                              />
                            ) : ('')}
                        </Flex>
                      ) : (
                        <Text
                          fontSize="lg"
                          fontWeight={data.title === 'Nama' && '500'}
                        >
                          {data.value}
                        </Text>
                      )}
                    </Flex>
                  ))
              )}
            </Box>
          </Box>
          <Box>
            <Text
              mt="14"
              fontWeight="500"
              fontSize="xl"
              color={'dietela.primary'}
            >
              Detail Jawaban
            </Text>
            <Flex
              my="6"
              rowGap={{ base: '10', md: '12' }}
              flexWrap="wrap"
              flexDirection="column"
            >
              {user.map(
                item =>
                  item.section === 'result-user' &&
                  item.data.map(data => (
                    <CardResult key={data.id} id={userId} data={data} />
                  ))
              )}
            </Flex>
          </Box>
        </Box>
      )}

      <Button
        px="6"
        mt="10"
        mb="5"
        borderRadius="full"
        leftIcon={<ArrowBackIcon />}
        colorScheme="teal"
        onClick={() => navigate(-1)}
      >
        Kembali
      </Button>
    </ContentLayout>
  );
}
