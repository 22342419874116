import React from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Divider,
  Link,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function Breadcrumbs({ routes }) {
  const navigate = useNavigate();

  const isCurrentPage = index => routes[index] === routes[routes.length - 1];

  return (
    <Box>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
      >
        {routes.map(({ name, url }, index) => (
          <BreadcrumbItem key={index}>
            <Link
              onClick={() => {
                navigate(url);
              }}
              _hover={{ textDecoration: 'none' }}
              color={isCurrentPage(index) ? 'black' : 'dietela.primary'}
            >
              {name}
            </Link>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
      <Divider mt="4" mb="8" borderColor="gray.300" />
    </Box>
  );
}
