import { createSlice } from '@reduxjs/toolkit';

export const modalSlicer = createSlice({
  name: 'modal',
  initialState: {
    data: {
    },
    timeFrame: '',
    pageIndex: 0,
    pageSize: 10,
  },

  reducers: {
    setIsOpen: (state, action) => {
      if (action.payload) {
        state.isOpen = action.payload;
      } else state.isOpen = false;
    },
    setTimeFrame: (state, action) => {
      if (action.payload) {
        state.timeFrame = action.payload;
      } else state.isOpen = {};
    },
    setPageIndex: (state, action) => {
      if (action.payload) {
        state.pageIndex = action.payload;
      } else state.pageIndex = 0;
    },
    setSizePage: (state, action) => {
      if (action.payload) {
        state.pageSize = action.payload;
      } else state.pageSize = 10;
    }
  }
});

export const { setIsOpen, setTimeFrame, setPageIndex, setSizePage  } = modalSlicer.actions;
export default modalSlicer.reducer;
