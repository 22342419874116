import { Box, Center, SimpleGrid } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import AuthImage from 'assets/image/Auth_image.png';

export default function AuthLayout() {
  return (
    <Box h="100vh">
      <SimpleGrid columns={{ base: 1, md: 2 }} h="full">
        <Box
          display={{ base: 'none', md: 'block' }}
          bgImage={AuthImage}
          bgRepeat="no-repeat"
          objectFit="cover"
          bgSize="cover"
          bgPosition="center"
        ></Box>
        <Center>
          <Outlet />
        </Center>
      </SimpleGrid>
    </Box>
  );
}
