import React from 'react';
import {
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import { FiHome, FiUser, FiUserPlus, FiPackage } from 'react-icons/fi';
import { Outlet, Link as RouterLink } from 'react-router-dom';

import logoDietela from '../../../assets/image/Logo_dietela.png';
import NavigationBar from 'components/common/NavigationBar';

const LinkItems = [
  { name: 'Dashboard', route: '/dashboard', icon: FiHome },
  // {
  //   name: 'Manajemen Assessment',
  //   route: '/manajemen-assesment',
  //   icon: FiFileText,
  // },
  { name: 'Manajemen User', route: '/manajemen-user', icon: FiUser },
  // { name: 'Manajemen Admin ', route: '/manajemen-admin', icon: FiUserPlus },
];

export default function SidebarLayout() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <NavigationBar onOpen={onOpen} contentSidebar={true} />
      <Box ml={{ base: 0, md: 290 }} p="4">
        <Outlet />
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 72 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image src={logoDietela} h="30px" alt="Logo Dietela" />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map(link => (
        <NavItem key={link.name} route={link.route} icon={link.icon}>
          {link.name}
        </NavItem>
      ))}
      {localStorage.getItem('access') === 1 || localStorage.getItem('access') === '1'
        ? (
          <NavItem key="Manajemen Admin" route="/manajemen-admin" icon={FiUserPlus}>
            Manajemen Admin
          </NavItem>
        ) : ('')}
      {localStorage.getItem('access') === 1 || localStorage.getItem('access') === '1'
        ? (
          <NavItem key="Manajemen Layanan Dietela" route="/manajemen-layanan-dietela" icon={FiPackage}>
            Manajemen Layanan Dietela
          </NavItem>
        ) : ('')}
    </Box>
  );
};

const NavItem = ({ route, icon, children, ...rest }) => {
  return (
    <Link
      as={RouterLink}
      to={route}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: '#008C81',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};
