import { Box } from '@chakra-ui/react';
import Breadcrumbs from 'components/common/PageBreadcrumb/Breadcrumbs';
import React from 'react';

export default function ContentLayout({ routes, children, admin }) {
  return (
    <Box p={{ base: admin ? '4' : '0', md: '8' }}>
      <Breadcrumbs routes={routes} />
      {children}
    </Box>
  );
}
