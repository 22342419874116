import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Center,
  Flex,
  Alert,
  AlertIcon,
  AlertDescription,
  Link,
  Box
} from '@chakra-ui/react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { forgotPassword } from 'utils/axios.services';

export default function ForgotPassword() {
  const [formData, setFormData] = useState({ email: '' });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  function handleForgotPassword() {
    forgotPassword(formData.email)
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setError(true);
      });
  }

  return (
    <Flex flexDirection="column" w="full" px="8" gap="28">
      <Heading fontSize={'3xl'} textAlign="center" color={'dietela.primary'}>
        Lupa Password
      </Heading>
      <Center h="full" w="full">
        <Flex flexDirection="column" w="md" gap="6">
          {success && (
            <Alert status="success" variant="solid" borderRadius="5">
              <AlertIcon />
              <AlertDescription>
                Berhasil, silakan cek email anda!
              </AlertDescription>
            </Alert>
          )}
          {error && (
            <Alert status="error" variant="solid" borderRadius="5">
              <AlertIcon />
              <AlertDescription>
                Sepertinya ada kesalahan pada server!
              </AlertDescription>
            </Alert>
          )}
          <FormControl id="email">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              placeholder="Masukkan email"
              size="lg"
              _focus={{ borderColor: 'dietela.primary' }}
              onChange={e =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
            />
          </FormControl>
          <Box w="full" textAlign="right">
            <Link
              as={RouterLink}
              to="/login"
              color={'dietela.primary'}
            >
              Masuk
            </Link>
          </Box>
          <Button
            colorScheme="teal"
            variant={'solid'}
            size="lg"
            borderRadius={'full'}
            onClick={handleForgotPassword}
          >
            Kirim
          </Button>
        </Flex>
      </Center>
    </Flex>
  );
}
