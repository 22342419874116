import {
  Box,
  Center,
  CircularProgress,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import ContentLayout from 'components/layout/ContentLayout';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  deleteAdmin,
  getAdminAccount,
  updateAdmin,
} from 'utils/axios.services';
import { setIsOpen } from 'reducer/modalReducer';
import ResponseDialog from 'components/common/ResponseDialog';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { EmailIcon } from '@chakra-ui/icons';
import { FaIdCardAlt, FaEye, FaEyeSlash, FaLock } from 'react-icons/fa';

export default function EditAdmin() {
  const params = useParams();
  const adminId = params.adminId;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [responseType, setResponseType] = useState();
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const [data, setData] = useState({
    id: '',
    name: '',
  });

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      newPassword: '',
      confirmNewPassword: '',
      adminLevel: '',
    },
  });

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const { data: response } = await getAdminAccount(adminId);

      setValue('id', response.data.id);
      setValue('firstName', response.data.first_name);
      setValue('lastName', response.data.last_name);
      setValue('email', response.data.email);
      setValue('adminLevel', response.data.access);

      setData({
        id: response.data.id,
        name: response.data.name,
      });

      setIsLoading(false);
    }

    fetchData();
  }, [adminId, params.adminId, setValue]);

  async function onSubmit() {
    const data = getValues();
    if (
      data.newPassword !== '' &&
      data.newPassword !== data.confirmNewPassword
    ) {
      toast.error('Password confirm not match');
    } else {
      await updateAdmin(data.id, data.firstName, data.lastName, data.email, data.newPassword, data.confirmNewPassword, data.adminLevel)
        .then(() => {
          navigate(`/manajemen-admin/edit-admin/${data.id}`);
          dispatch(setIsOpen(true));
          setResponseType('success');
        })
        .catch(() => {
          // toast.error(error.response.data.message);
          dispatch(setIsOpen(true));
          setResponseType('fail');
        });
    }
  }

  async function handleDelete() {
    const data = getValues();
    await deleteAdmin(data.id)
      .then(() => {
        navigate('/manajemen-admin');
        dispatch(setIsOpen(true));
      })
      .catch(() => {
        dispatch(setIsOpen(true));
        setResponseType('fail');
      });
  }

  const routes = [
    {
      name: 'Manajemen Admin',
      url: '/manajemen-admin',
    },
    {
      name: 'Edit Admin',
    },
  ];

  return (
    <ContentLayout routes={routes}>
      <Heading fontSize="28" mb="8">
        Edit Admin
      </Heading>
      {isLoading ? (
        <Center>
          <CircularProgress isIndeterminate color="dietela.primary" />
        </Center>
      ) : (
        <Box
          bgColor="white"
          borderRadius="1rem"
          p="5"
          w={{
            base: 'full',
            lg: '600px',
          }}
        >
          <Flex as="form" flexDirection="column" gap="6">
            <FormControl isInvalid={errors.firstName} isRequired>
              <FormLabel>Nama Depan</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<FaIdCardAlt color="teal" />}
                />
                <Input
                  id="firstName"
                  placeholder={'Masukkan Nama Depan'}
                  {...register('firstName', {
                    required: 'Nama Depan wajib diisi',
                  })}
                  color="dietela.primary"
                  fontFamily="roboto"
                  fontWeight="400px"
                  focusBorderColor="teal"
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.firstName && errors.firstName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.lastName} isRequired>
              <FormLabel>Nama Belakang</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<FaIdCardAlt color="teal" />}
                />
                <Input
                  id="lastName"
                  placeholder="Masukkan Nama Belakang"
                  {...register('lastName', {
                    required: 'Nama Belakang wajib diisi',
                  })}
                  color="dietela.primary"
                  fontFamily="roboto"
                  fontWeight="400px"
                  focusBorderColor="teal"
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.lastName && errors.lastName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.email} isRequired>
              <FormLabel>Email</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<EmailIcon color="teal" />}
                />
                <Input
                  id="email"
                  type="email"
                  placeholder="Masukkan Email"
                  {...register('email', {
                    required: 'Email wajib diisi',
                  })}
                  color="dietela.primary"
                  fontFamily="roboto"
                  fontWeight="400px"
                  focusBorderColor="teal"
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.newPassword}>
              <FormLabel>Password Baru</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<FaLock color="teal" />}
                />
                <Input
                  id="newPassword"
                  type={newPasswordShown ? 'text' : 'password'}
                  placeholder="Password Baru"
                  {...register('newPassword', {
                    minLength: 5,
                  })}
                  color="dietela.primary"
                  fontFamily="roboto"
                  fontWeight="400px"
                  focusBorderColor="teal"
                />
                <InputRightElement
                  children={newPasswordShown ? (<FaEyeSlash color="teal" />) : (<FaEye color="teal" />)}
                  onClick={()=>{
                    if (newPasswordShown) setNewPasswordShown(false); 
                    else setNewPasswordShown(true);
                  }}
                />
              </InputGroup>
            </FormControl>
            <FormControl isInvalid={errors.confirmNewPassword}>
              <FormLabel>Ulangi Password Baru</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<FaLock color="teal" />}
                />
                <Input
                  id="confirmNewPassword"
                  type={confirmPasswordShown ? 'text' : 'password'}
                  placeholder="Ulangi Password Baru"
                  {...register('confirmNewPassword', {
                    required:
                      getValues().confirmNewPassword !==
                      getValues().newPassword,
                  })}
                  color="dietela.primary"
                  fontFamily="roboto"
                  fontWeight="400px"
                  focusBorderColor="teal"
                />
                <InputRightElement
                  children={confirmPasswordShown ? (<FaEyeSlash color="teal" />) : (<FaEye color="teal" />)}
                  onClick={()=>{
                    if (confirmPasswordShown) setConfirmPasswordShown(false); 
                    else setConfirmPasswordShown(true);
                  }}
                />
              </InputGroup>
            </FormControl>
            <FormControl isInvalid={errors.adminLevel}>
              <FormLabel>Level Admin</FormLabel>
              <Select
                id="adminLevel"
                placeholder="Admin Level"
                {...register('adminLevel', {
                  required: 'Level Admin wajib diisi',
                })}
              >
                <option value="2">Admin</option>
                <option value="1">Super Admin</option>
              </Select>
              <FormErrorMessage>
                {errors.adminLevel && errors.adminLevel.message}
              </FormErrorMessage>
            </FormControl>
            <Flex
              rowGap="3"
              columnGap="5"
              mt="3"
              flexDirection={{
                base: 'column-reverse',
                sm: 'row',
              }}
            >
              <ConfirmDialog
                dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/konfirmasi_delete.png"
                ConfirmText={`Anda yakin ingin menghapus admin "${data.name}"?`}
                BtnConfirmText="Hapus"
                onConfirm={handleDelete}
                color="red"
              />
              { responseType === 'success'
                ? (
                  <ResponseDialog
                    dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/berhasil_simpandata.png"
                    DialogText="Data berhasil diubah!"
                    BtnDialogText="Selesai"
                  />
                ) : (
                  <ResponseDialog
                    dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/gagal_simpandata.svg"
                    DialogText="Gagal menyimpan data"
                    BtnDialogText="Selesai"
                  />
                )}

              <ConfirmDialog
                dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/konfirmasi_simpan.png"
                ConfirmText="Anda yakin ingin mengubah data profil anda?"
                BtnConfirmText="Simpan"
                onConfirm={onSubmit}
                color="teal"
              />
            </Flex>
          </Flex>
        </Box>
      )}
    </ContentLayout>
  );
}
