import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Flex,
  Box,
  Button,
  Heading,
  Text,
  Skeleton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox, Stack, Divider, Center, CheckboxGroup, RadioGroup, Radio, MenuList, Menu, MenuButton,
} from '@chakra-ui/react';
import {CalendarIcon, TriangleDownIcon, TriangleUpIcon, DownloadIcon, ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table';
import GlobalFilter from 'components/pages/ManajemenUser/GlobalFilter';
import Pagination from 'components/pages/ManajemenUser/Pagination';
import { useNavigate } from 'react-router-dom';
import ContentLayout from 'components/layout/ContentLayout';
import { getListUser, getReporting } from 'utils/axios.services';
import moment from 'moment';
import ResponseDialog from 'components/common/ResponseDialog';
import { useSelector } from 'react-redux';
import { DateRange } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';

export default function ManajemenUser() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const indexPage = useSelector((state) => state.modal.pageIndex);
  const sizePage = useSelector((state) => state.modal.pageSize);
  const [isLoaded, setIsLoaded] = useState(false);
  const [filterTime, setFilterTime] = React.useState('weekly')
  const [filterKategori, setFilterKategori] = React.useState(['berat_badan', 'kehamilan', 'penyakit'])
  const [filterData, setFilterData] = React.useState(['part', 'result'])

  const [loadingDownloadReport, setLoadingDownloadReport] = useState(false);

  useEffect(() => {
    getListUser().then(res => {
      setUserData(res.data.user);
      setIsLoaded(true);
    });
  }, []);

  const data = React.useMemo(() => userData, [userData]);

  const [date, setDate] = useState([
    {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate(),
      key: 'selection'
    }
  ]);

  const handlePrevDate = () => {
    setFilterTime('')
    const diffDay = moment(date[0].endDate).diff(moment(date[0].startDate), 'days')
    setDate([{
      startDate: moment(date[0].startDate).subtract(diffDay+1, 'days').toDate(),
      endDate: moment(date[0].endDate).subtract(diffDay+1, 'days').toDate(),
      key: 'selection'
    }])
  }

  const handleNextDate = () => {
    setFilterTime('')
    const diffDay = moment(date[0].endDate).diff(moment(date[0].startDate), 'days')
    setDate([{
      startDate: moment(date[0].startDate).add(diffDay+1, 'days').toDate(),
      endDate: moment(date[0].endDate).add(diffDay+1, 'days').toDate(),
      key: 'selection'
    }])
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Nama',
        accessor: 'username',
      },
      {
        Header: 'Jenis Kelamin',
        accessor: 'gender',
      },
      {
        Header: 'Usia',
        accessor: 'age',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Kondisi',
        accessor: 'LogTables[0].values',
      },
      {
        Header: 'Last Update',
        accessor: 'updatedAt',
      },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <Button
            px="6"
            variant="outline"
            colorScheme="teal"
            borderRadius="full"
            onClick={() => {
              navigate(`detail-user/${row.values.id}`);
              // alert(JSON.stringify(row.values));
            }}
          >
            Lihat
          </Button>
        ),
      },
    ],
    [navigate]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    state,
  } = useTable({ columns, data, initialState: {pageIndex:indexPage, pageSize:sizePage} }, useGlobalFilter, useSortBy, usePagination);

  const routes = [
    {
      name: 'Manajemen User',
    },
  ];

  return (
    <ContentLayout routes={routes}>
      <ResponseDialog
        dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/berhasil_simpandata.png"
        DialogText="Data berhasil dihapus!"
        BtnDialogText="Selesai"
      />
      <Flex justifyContent='space-between' alignItems='center' mb="8">
        <Heading fontSize="28">
        Manajemen User
        </Heading>
        <Button
          colorScheme='teal'
          onClick={onOpen}
          rightIcon={<DownloadIcon />}
        >
          Download
        </Button>
      </Flex>
      <Box bgColor="white" borderRadius="1rem" overflowX="auto">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          w="100%"
          py="3"
          px="5"
        >
          <Heading fontWeight="semibold" fontSize="lg">
            List of User
          </Heading>
          <GlobalFilter
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Flex>
        <Skeleton isLoaded={isLoaded}>
          <Table {...getTableProps()}>
            <Thead backgroundColor="teal.100">
              {headerGroups.map((headerGroup, headerGroupIndex) => (
                <Tr key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, columnIndex) => (
                    <Th
                      key={columnIndex}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      color="teal.800"
                      fontWeight="semibold"
                    >
                      {column.render('Header')}
                      <chakra.span pl="4">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                          )
                        ) : null}
                      </chakra.span>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <Tr key={rowIndex} {...row.getRowProps()}>
                    {row.cells.map((cell, cellIndex) => (
                      <Td key={cellIndex} {...cell.getCellProps()}>
                        {cell.column.id === 'updatedAt' ? (
                          <Text>
                            {moment(cell.value).format('DD-MM-YYYY')}
                          </Text>
                        ) : (
                          <Text>{cell.render('Cell')}</Text>
                        )}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {isLoaded === true ? (
            <Pagination
              pageSize={state.pageSize}
              pageCount={pageCount}
              setPageSize={setPageSize}
              pageIndex={state.pageIndex}
              pageOptions={pageOptions}
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              nextPage={nextPage}
              previousPage={previousPage}
              gotoPage={gotoPage}
            />
          ) : ('')}
        </Skeleton>
      </Box>

      {/* MODAL DOWNLOAD */}
      <Modal size='3xl' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack width='100%' spacing={10} direction='row' justifyContent='center' alignItems='start'>
              <Box>
                <Text fontSize='lg' fontWeight='bold' marginBottom='8px'>
                  Time Range
                </Text>
                <Menu>
                  <Stack direction='row' spacing='0' >
                    <Button
                      size='sm'
                      colorScheme='teal'
                      variant='outline'
                      borderRightRadius='0'
                      borderRight='none'
                      onClick={handlePrevDate}
                    >
                      <ChevronLeftIcon/>
                    </Button>
                    <MenuButton
                      as={Button}
                      size='sm'
                      colorScheme='teal'
                      variant='outline'
                      fontWeight='normal'
                      rightIcon={<CalendarIcon/>}
                      borderRadius='0'
                    >
                      {date[0].startDate && date[0].endDate ? `${moment(date[0].startDate).format('DD/MM/YYYY')} - ${moment(date[0].endDate).format('DD/MM/YYYY')}` : 'Select Date'}
                    </MenuButton>
                    <Button
                      size='sm'
                      colorScheme='teal'
                      variant='outline'
                      borderLeftRadius='0'
                      borderLeft='none'
                      onClick={handleNextDate}
                    >
                      <ChevronRightIcon/>
                    </Button>
                  </Stack>
                  <MenuList padding='0'>
                    <DateRange
                      editableDateInputs={true}
                      onChange={item => {
                        setFilterTime('')
                        setDate([item.selection]);
                      }}
                      moveRangeOnFirstSelection={false}
                      ranges={date}
                      rangeColors={['#319795']}
                    />
                  </MenuList>
                </Menu>

                <RadioGroup marginTop={2} colorScheme='teal' onChange={setFilterTime} value={filterTime}>
                  <Stack spacing={2} direction='column'>
                    <Radio value='weekly'>1 Minggu</Radio>
                    <Radio value='monthly'>1 Bulan</Radio>
                  </Stack>
                </RadioGroup>
              </Box>

              <Center height='130px'>
                <Divider orientation='vertical' />
              </Center>

              <Box>
                <Text fontSize='lg' fontWeight='bold' marginBottom='8px'>
                  Pilih Data
                </Text>
                <CheckboxGroup colorScheme='teal' value={filterKategori} onChange={setFilterKategori} >
                  <Stack spacing={2} direction='column'>
                    <Checkbox value='berat_badan'>
                      Berat Badan
                    </Checkbox>
                    <Checkbox value='kehamilan'>
                      Kehamilan
                    </Checkbox>
                    <Checkbox value='penyakit'>
                      Penyakit
                    </Checkbox>
                  </Stack>
                </CheckboxGroup>
              </Box>

              <Center height='130px'>
                <Divider orientation='vertical' />
              </Center>

              <Box>
                <Text fontSize='lg' fontWeight='bold' marginBottom='8px'>
                  Pilih Data
                </Text>
                <CheckboxGroup colorScheme='teal' value={filterData} onChange={setFilterData}>
                  <Stack spacing={2} direction='column'>
                    <Checkbox value='part'>
                    Part 1-7
                    </Checkbox>
                    <Checkbox value='result'>
                    Result
                    </Checkbox>
                  </Stack>
                </CheckboxGroup>
              </Box>
            </Stack>


          </ModalBody>
          <ModalFooter>
            <Stack justifyContent='center' alignItems='center' width='100%'>
              <Button
                width='100%'
                maxWidth='330px'
                colorScheme='teal'
                onClick={() => {
                  setLoadingDownloadReport(true)
                  getReporting({
                    timerange: filterTime ? filterTime : undefined,
                    startdate: !filterTime ? date[0].startDate : undefined,
                    enddate: !filterTime ? date[0].endDate : undefined,
                    beratbadan: filterKategori.includes('berat_badan') ? 1 : 0,
                    kehamilan: filterKategori.includes('kehamilan') ? 1 : 0,
                    penyakit: filterKategori.includes('penyakit') ? 1 : 0,
                    part1_7: filterData.includes('part') ? 1 : 0,
                    result: filterData.includes('result') ? 1 : 0,
                  }).then(res => {
                    const url = window.URL.createObjectURL(res.data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'dietela-quiz-report.xlsx');
                    document.body.appendChild(link);
                    link.click();
                  }).finally(() => {
                    onClose();
                    setLoadingDownloadReport(false)
                  })
                }}
                rightIcon={<DownloadIcon />}
                borderRadius='1000px'
                isLoading={loadingDownloadReport}
              >
                Download Data (xls)
              </Button>
            </Stack>
            {/*<Button colorScheme='blue' mr={3} onClick={}>*/}
            {/*  Close*/}
            {/*</Button>*/}
            {/*<Button variant='ghost'>Secondary Action</Button>*/}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ContentLayout>
  );
}
