import { Box, Flex, Tabs, TabList, Tab, TabPanels, TabPanel, Select } from '@chakra-ui/react';
import BarChart from 'components/pages/Dashboard/BarChart';
import LineChart from 'components/pages/Dashboard/LineChart';
import PieChart from 'components/pages/Dashboard/PieChart';
import AreaChart from 'components/pages/Dashboard/AreaChart';
import MultiPieChart from 'components/pages/Dashboard/MultiPieChart';
import React, { useEffect, useState } from 'react';
import { getDashboardData } from 'utils/axios.services';
import { useDispatch } from 'react-redux';
import { setTimeFrame } from 'reducer/modalReducer';
// import { datasets } from './data';

function Dashboard() {
  const dispatch = useDispatch();
  const [dashboardDataset, setDashboardDataset] = useState([]);
  const [hide, setHide] = useState('');

  const handleChange = event => {
    // for(let i=0; i<document.getElementsByClassName('timeframe-selector').length; i++) {
    //   document.getElementsByClassName('timeframe-selector')[i].value = event.target.value;
    //   // document.getElementsByClassName('timeframe-selector')[i].onchange();
    // }
    dispatch(setTimeFrame(event.target.value));
  };

  useEffect(() => {
    getDashboardData().then(res => {
      setDashboardDataset(res.data.data);
    });
  }, []);

  function chartType(type, dataset) {
    if (type === 'bar') {
      return <BarChart datasets={dataset} />;
    } else if (type === 'line') {
      return <LineChart datasets={dataset} />;
    } else if (type === 'pie') {
      return <PieChart datasets={dataset} />;
    } else if (type === 'area') {
      return <AreaChart datasets={dataset} />;
    } else if (type === 'multipie') {
      return <MultiPieChart datasets={dataset} />;
    }
  }

  function boxWidth(dataset) {
    if (
      dataset.type === 'area' ||
      dataset.type === 'multipie' ||
      dataset.title === 'Email Submission' ||
      dataset.title === 'Pekerjaan' ||
      dataset.title === 'Rating'
    ) {
      return '100%';
    }

    return 'calc(50% - 20px)';
  }

  const tabs = dashboardDataset.map((dataset) => {
    return <Tab
      color="#646161"
      fontFamily="montserrat"
      bg="#C4C4C4"
      fontWeight={700}
      _selected={{ color: 'black', bg: 'dietela.secondary', fontcolor: 'black', mt: '0' }}
      mt="4"
      mr="1"
    >
      {dataset.tab}
    </Tab>;
  });

  const panels = dashboardDataset.map((dataset) => {
    return (
      <TabPanel pr="0px" pl="0px" pt="0px">
        <Flex gap="10" flexWrap="wrap">
          {
            dataset.data.map((data, index) => {
              return (
                <Box
                  key={index}
                  w={{
                    base: '100%',
                    xl: boxWidth(data),
                  }}
                >
                  {chartType(data.type, data)}
                </Box>
              );
            })
          }
        </Flex>
      </TabPanel>
    );
  });

  return (
    <Box p={{ base: '0', md: '8' }}>
      <Flex
        justifyContent="end"
      >
        <Select
          onChange={handleChange}
          width="fit-content"
          bg={'#F8F9FA'}
          variant={'filled'}
          border={'1px solid'}
          borderColor={'#DDDDDD'}
          _focus={{
            border: '2px solid',
            borderColor: 'dietela.primary',
            boxShadow: 'none',
          }}
        >
          <option value="Harian">Harian</option>
          <option value="Bulanan">Bulanan</option>
          <option value="Tahunan" hidden={hide}>Tahunan</option>
        </Select>
      </Flex>
      <Tabs w="100%" variant='enclosed' onChange={(index) => {
        if (index === 1) {
          setHide(true)
        } else {
          setHide(false)
        }
      }}>
        <TabList
          borderBottom="0px"
          pl="7px"
        >
          {tabs}
        </TabList>
        <TabPanels>
          {panels}
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default Dashboard;
