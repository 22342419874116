import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  SkeletonText,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAnswerData } from 'utils/axios.services';
import ContentLayout from 'components/layout/ContentLayout';

export default function DetailJawaban() {
  const navigate = useNavigate();
  const params = useParams();
  const userId = params.userId;
  let part = params.jawabanId;
  const [answers, setAnswers] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      await getAnswerData(userId, part).then(res => {
        setAnswers(res.data);
      });
      setIsLoading(false);
    }
    getData();
  }, [part, userId]);

  const routes = [
    {
      name: 'Manajemen User',
      url: '/manajemen-user',
    },
    {
      name: 'Detail User',
      url: `/manajemen-user/detail-user/${userId}`,
    },
    {
      name: 'Detail Jawaban',
    },
  ];

  return (
    <ContentLayout routes={routes}>
      <Heading fontSize="28" mb="8">
        Detail Jawaban
      </Heading>
      <Flex
        flexDir="column"
        border="1px"
        p="6"
        borderRadius="10"
        borderColor="dietela.primary"
        bgColor="white"
      >
        {isLoading ? (
          <Box padding="6">
            <SkeletonText noOfLines={5} spacing="4" />
          </Box>
        ) : (
          <Box>
            <Heading fontSize="24" mt="2" mb="8">
              {answers.title}
            </Heading>
            {answers.data.map(answer => (
              <Box key={answer.id}>
                <Text color="dietela.primary">{answer.question}</Text>
                <Text my="3" fontWeight="500">
                  {answer.answer}
                </Text>
              </Box>
            ))}
          </Box>
        )}

        <Flex width="100%" justify="space-between" pt="5" gap="5">
          <Button
            px="6"
            borderRadius="full"
            leftIcon={<ArrowBackIcon />}
            colorScheme="teal"
            isDisabled={+part === 1}
            onClick={() => {
              if (part === '3A') {
                part = 2;
                navigate(
                  `../manajemen-user/detail-user/${userId}/detail-jawaban/2`,
                  { replace: true }
                );
              } else if (part === '3B') {
                part = '3A';
                navigate(
                  `../manajemen-user/detail-user/${userId}/detail-jawaban/3A`,
                  { replace: true }
                );
              } else if (part === '4') {
                part = '3B';
                navigate(
                  `../manajemen-user/detail-user/${userId}/detail-jawaban/3B`,
                  { replace: true }
                );
              } else if (part === '5A') {
                part = 4;
                navigate(
                  `../manajemen-user/detail-user/${userId}/detail-jawaban/4`,
                  { replace: true }
                );
              } else if (part === '5B') {
                part = '5A';
                navigate(
                  `../manajemen-user/detail-user/${userId}/detail-jawaban/5A`,
                  { replace: true }
                );
              } else if (part === '6') {
                part = '5B';
                navigate(
                  `../manajemen-user/detail-user/${userId}/detail-jawaban/5B`,
                  { replace: true }
                );
              } else {
                navigate(
                  `../manajemen-user/detail-user/${userId}/detail-jawaban/${
                    +part - 1
                  }`,
                  { replace: true }
                );
              }
              setIsLoading(true);
            }}
          >
            Kembali
          </Button>
          <Button
            px="6"
            borderRadius="full"
            rightIcon={<ArrowForwardIcon />}
            colorScheme="teal"
            isDisabled={+part === 7}
            onClick={() => {
              if (part === '2') {
                part = '3A';
                navigate(
                  `../manajemen-user/detail-user/${userId}/detail-jawaban/3A`,
                  { replace: true }
                );
              } else if (part === '3A') {
                part = '3B';
                navigate(
                  `../manajemen-user/detail-user/${userId}/detail-jawaban/3B`,
                  { replace: true }
                );
              } else if (part === '3B') {
                part = 4;
                navigate(
                  `../manajemen-user/detail-user/${userId}/detail-jawaban/4`,
                  { replace: true }
                );
              } else if (part === '4') {
                part = '5A';
                navigate(
                  `../manajemen-user/detail-user/${userId}/detail-jawaban/5A`,
                  { replace: true }
                );
              } else if (part === '5A') {
                part = '5B';
                navigate(
                  `../manajemen-user/detail-user/${userId}/detail-jawaban/5B`,
                  { replace: true }
                );
              } else if (part === '5B') {
                part = 6;
                navigate(
                  `../manajemen-user/detail-user/${userId}/detail-jawaban/6`,
                  { replace: true }
                );
              } else {
                navigate(
                  `../manajemen-user/detail-user/${userId}/detail-jawaban/${
                    +part + 1
                  }`,
                  { replace: true }
                );
              }
              setIsLoading(true);
            }}
          >
            Selanjutnya
          </Button>
        </Flex>
      </Flex>
      <Button
        px="6"
        mt="20"
        mb="5"
        borderRadius="full"
        leftIcon={<ArrowBackIcon />}
        colorScheme="teal"
        onClick={() => navigate(-1)}
      >
        Kembali
      </Button>
    </ContentLayout>
  );
}
