import { Box, Flex, Heading, Select, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Rate } from 'antd';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const backgroundColor = [
  '#0263FF',
  '#FF7723',
  '#8E30FF',
  '#FEB139',
  '#0558BE',
  '#39A1A2',
  '#55A380',
  '#A760FF',
  '#D6044A',
  '#FE6495',
  '#C133AE',
  '#904F9B',
];

function BarChart({ datasets }) {
  const timeFrame = useSelector((state) => state.modal.timeFrame);
  const [value, setValue] = useState(datasets.filterBy[0].select);
  const [dataset, setDataset] = useState(datasets.filterBy[0]);

  const handleChange = event => {
    setValue(event.target.value);
    filterChart(event.target.value);
  };

  const filterChart = filterValue => {
    const filteredDataset = datasets.filterBy.find(
      dataset => dataset.select === filterValue
    );
    setDataset(filteredDataset);
  };

  useEffect(() => {
    if (timeFrame !== '') {
      setValue(timeFrame);
      const filteredDataset = datasets.filterBy.find(
        dataset => dataset.select === timeFrame
      );
      if (filteredDataset) {
        setDataset(filteredDataset);
      }
    }
  }, [timeFrame])

  const data = {
    datasets: [
      {
        label: datasets.title,
        backgroundColor: backgroundColor,
        borderWidth: 0,
        data: dataset.data,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.raw.detail.map((v) => (
              `⭐ ${v?.label.padEnd(4, ' ') || '-'}: ${v?.value|| '-'}`
            ))
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: datasets.xAxis,
        },
      },
      y: {
        title: {
          display: true,
          text: datasets.yAxis,
        },
        min: 0,
        max: 5,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <Box
      h="full"
      bg="white"
      overflow="hidden"
      borderRadius="8px"
      border="1px solid"
      borderColor="dietela.primary"
    >
      <Box bg="dietela.primary" p="5">
        <Heading size="sm" color="white">
          {datasets.title}
        </Heading>
      </Box>
      <Flex flexDirection="column" p="5">
        <Select
          className="timeframe-selector"
          value={value}
          onChange={handleChange}
          mb={10}
          width={'50%'}
          bg={'#F8F9FA'}
          variant={'filled'}
          border={'1px solid'}
          borderColor={'#DDDDDD'}
          _focus={{
            border: '2px solid',
            borderColor: 'dietela.primary',
            boxShadow: 'none',
          }}
        >
          {datasets.filter.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
        {datasets.title === 'Rating' ? (
          <Flex alignItems="center" justifyContent="center" mb={3}>
            <Flex gap={4} alignItems="center" justifyContent="center" bg="teal" width="400px" height="50px" borderRadius="10px">
              <Text textColor="white">Rata-rata Rating:</Text>
              <Rate style={{ '--rate-star-zero-color': 'gray' }} allowHalf disabled value={dataset?.rata_rata_rating || 0} />
              <Text textColor="white">({dataset?.rata_rata_rating || 0})</Text>
            </Flex>
          </Flex>) : null}
        <Bar data={data} options={options} />
      </Flex>
    </Box>
  );
}

export default BarChart;
