import { Box, Flex, Heading, Select } from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

function LineChart({ datasets }) {
  const timeFrame = useSelector((state) => state.modal.timeFrame);
  const [value, setValue] = useState(datasets.filterBy[0].select);
  const [dataset, setDataset] = useState(datasets.filterBy[0]);

  const handleChange = event => {
    setValue(event.target.value);
    filterChart(event.target.value);
  };

  const filterChart = filterValue => {
    const filteredDataset = datasets.filterBy.find(
      dataset => dataset.select === filterValue
    );
    setDataset(filteredDataset);
  };

  useEffect(() => {
    if (timeFrame !== '') {
      setValue(timeFrame);
      const filteredDataset = datasets.filterBy.find(
        dataset => dataset.select === timeFrame
      );
      if (filteredDataset) {
        setDataset(filteredDataset);
      }
    }
  }, [timeFrame])

  const data = {
    datasets: [
      {
        label: datasets.title,
        backgroundColor: '#00A68C',
        borderColor: '#00A68C',
        borderWidth: 2,
        data: dataset.data,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return 'Total: ' + context.parsed.y;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: datasets.xAxis,
        },
      },
      y: {
        title: {
          display: true,
          text: datasets.yAxis,
        },
        min: 0,
        ticks: {
          stepSize: 10,
        },
      },
    },
  };

  return (
    <Box
      bg="white"
      overflow="hidden"
      borderRadius="8px"
      border="1px solid"
      borderColor="dietela.primary"
    >
      <Box bg="dietela.primary" p="5">
        <Heading size="sm" color="white">
          {datasets.title}
        </Heading>
      </Box>
      <Flex flexDirection="column" p="5">
        <Select
          className="timeframe-selector"
          value={value}
          onChange={handleChange}
          mb={10}
          width={'50%'}
          bg={'#F8F9FA'}
          variant={'filled'}
          border={'1px solid'}
          borderColor={'#DDDDDD'}
          _focus={{
            border: '2px solid',
            borderColor: 'dietela.primary',
            boxShadow: 'none',
          }}
        >
          {datasets.filter.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
        <Line data={data} options={options} />
      </Flex>
    </Box>
  );
}

export default LineChart;
