import {
  Box,
  Button,
  Center,
  CircularProgress,
  Flex,
  Heading,
  ListItem,
  OrderedList,
  Text,
} from '@chakra-ui/react';
import ContentLayout from 'components/layout/ContentLayout';
import React, { useEffect, useState } from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import StatusGraph from 'components/common/StatusGraph';
import { getResultData } from 'utils/axios.services';

export default function DetailResult() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState([]);
  const params = useParams();
  const userId = params.userId;

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const { data: response } = await getResultData(params.userId);
      setResult(response.result);
      setIsLoading(false);
    }
    getData();
  }, []);

  const routes = [
    {
      name: 'Manajemen User',
      url: '/manajemen-user',
    },
    {
      name: 'Detail User',
      url: `/manajemen-user/detail-user/${userId}`,
    },
    {
      name: 'Detail Result',
    },
  ];

  return (
    <ContentLayout routes={routes}>
      <Heading fontSize="28" mb="8">
        Detail Result
      </Heading>
      {isLoading ? (
        <Center>
          <CircularProgress isIndeterminate color="dietela.primary" />
        </Center>
      ) : (
        <Flex
          flexDir="column"
          border="1px"
          p="6"
          borderRadius="10"
          borderColor="dietela.primary"
          bgColor="white"
        >
          <Flex flexDirection="column" gap="12">
            {result.map(data => (
              <Flex key={data.id} flexDirection="column" gap="3">
                <Heading size="md">{data.title}</Heading>
                <Box boxShadow="lg" p="6" rounded="md" bg="white">
                  {data.title === 'Status Graph' ? (
                    <StatusGraph props={data.description} />
                  ) : (
                    <>
                      {data.indeks && (
                        <Box mb={6}>
                          <Heading color="dietela.primary">
                            {data.indeks}
                          </Heading>
                          <Heading size="sm" fontWeight="600">
                            Status Gizi {data.status}
                          </Heading>
                        </Box>
                      )}
                      {data.description.split('|').map(
                        (text) =>
                          (
                            <Text>
                              {text.includes('~') && (text.includes('*') === 'false' || text.includes('*') === false)
                                ? (
                                  text.split('~').map((bold, index) =>
                                    <Text as="span" fontWeight={index === 1 ? 'bold' : ''}>{bold}</Text>)
                                ) : ('')}
                              {text.includes('*') && (text.includes('~') === 'false' || text.includes('~') === false)
                                ? (
                                  <OrderedList>
                                    {text.split('*').map((text) =>
                                      <ListItem>{text}</ListItem>)}
                                  </OrderedList>
                                ) : ('')}
                              {(text.includes('*') === 'false' || text.includes('*') === false) && (text.includes('~') === 'false' || text.includes('~') === false)
                                ? (text) : ('')}
                            </Text>
                          )
                      )}
                    </>
                  )}
                </Box>
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}
      <Button
        px="6"
        mt="20"
        mb="5"
        borderRadius="full"
        leftIcon={<ArrowBackIcon />}
        colorScheme="teal"
        onClick={() => navigate(-1)}
      >
        Kembali
      </Button>
    </ContentLayout>
  );
}
