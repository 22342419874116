import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Flex,
  Box,
  Button,
  Heading,
  Text,
} from '@chakra-ui/react';
import { AddIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table';
import GlobalFilter from 'components/pages/ManajemenUser/GlobalFilter';
import Pagination from 'components/pages/ManajemenUser/Pagination';
import { useNavigate } from 'react-router-dom';
import ContentLayout from 'components/layout/ContentLayout';
import moment from 'moment';
import { getListAdmin } from 'utils/axios.services';
import ResponseDialog from 'components/common/ResponseDialog';

export default function ManajemenAdmin() {
  const navigate = useNavigate();
  const [dataAdmin, setDataAdmin] = useState([]);

  const data = React.useMemo(() => dataAdmin, [dataAdmin]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
      },
      {
        Header: 'Nama Depan',
        accessor: 'first_name',
      },
      {
        Header: 'Nama Belakang',
        accessor: 'last_name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <Button
            px="6"
            variant="outline"
            colorScheme="teal"
            borderRadius="full"
            onClick={() => {
              navigate(`edit-admin/${row.values.id}`);
            }}
          >
            Edit
          </Button>
        ),
      },
    ],
    [navigate]
  );

  useEffect(() => {
    getListAdmin().then(res => {
      setDataAdmin(res.data.data);
    });
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    state,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);

  const routes = [
    {
      name: 'Manajemen Admin',
    },
  ];

  return (
    <ContentLayout routes={routes}>
      <ResponseDialog
        dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/berhasil_simpandata.png"
        DialogText="Data berhasil dihapus!"
        BtnDialogText="Selesai"
      />
      <Flex
        justifyContent="space-between"
        alignItems={{
          base: 'start',
          md: 'center',
        }}
        mb="8"
        gap="5"
        minH="50px"
        flexDirection={{
          base: 'column',
          md: 'row',
        }}
      >
        <Heading fontSize="28">Manajemen Admin</Heading>
        <Button
          py="6"
          px="8"
          borderRadius="full"
          leftIcon={<AddIcon />}
          color="#695100"
          bg="dietela.secondary-variant"
          _hover={{
            bg: 'dietela.secondary',
          }}
          _focus={{
            bg: 'dietela.secondary-variant',
          }}
          onClick={() => {
            navigate('tambah-admin');
          }}
        >
          Tambah Admin
        </Button>
      </Flex>
      <Box bgColor="white" borderRadius="1rem" overflowX="auto">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          w="100%"
          py="3"
          px="5"
        >
          <Heading fontWeight="semibold" fontSize="lg">
            List of Admin
          </Heading>
          <GlobalFilter
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Flex>
        <Table {...getTableProps()}>
          <Thead backgroundColor="teal.100">
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    color="teal.800"
                    fontWeight="semibold"
                  >
                    {column.render('Header')}
                    <chakra.span pl="4">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <Td {...cell.getCellProps()}>
                      {cell.column.id === 'updatedAt' ? (
                        <>
                          <Text>{moment(cell.value).format('DD-MM-YYYY')}</Text>
                          <Text fontSize="sm" color="#888888" mt="2">
                            {moment(cell.value).fromNow()}
                          </Text>
                        </>
                      ) : (
                        <Text>{cell.render('Cell')}</Text>
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Pagination
          pageSize={state.pageSize}
          pageCount={pageCount}
          setPageSize={setPageSize}
          pageIndex={state.pageIndex}
          pageOptions={pageOptions}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          previousPage={previousPage}
          gotoPage={gotoPage}
        />
      </Box>
    </ContentLayout>
  );
}
