import React, { useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Box, Flex, Heading, Select } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function PieChart({ datasets }) {
  const date = new Date();
  date.setDate(date.getDate() - 7);
  const dateNow = new Date();
  const timeFrame = useSelector((state) => state.modal.timeFrame);
  const [value, setValue] = useState(datasets.filterBy[0].select);
  const [dataset, setDataset] = useState(datasets.filterBy[0]);
  const [title, setTitle] = useState(date.toLocaleDateString('id-ID') + ' s/d ' + dateNow.toLocaleDateString('id-ID'));

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  const handleChange = event => {
    setValue(event.target.value);
    filterChart(event.target.value);
    if (event.target.value === 'Harian') {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      const dateNow = new Date();
      setTitle(date.toLocaleDateString('id-ID') + ' s/d ' + dateNow.toLocaleDateString('id-ID'));
    } else if (event.target.value === 'Bulanan') {
      const date = new Date();
      const firstDayCurrentMonth = getFirstDayOfMonth(
        date.getFullYear(),
        date.getMonth(),
      );
      const dateNow = new Date();
      setTitle(firstDayCurrentMonth.toLocaleDateString('id-ID') + ' s/d ' + dateNow.toLocaleDateString('id-ID'));
    } else {
      const dateNow = new Date();
      setTitle('1/1/' + dateNow.getFullYear() + ' s/d ' + dateNow.toLocaleDateString('id-ID'));
    }
  };

  const filterChart = filterValue => {
    const filteredDataset = datasets.filterBy.find(
      dataset => dataset.select === filterValue
    );
    setDataset(filteredDataset);
  };

  useEffect(() => {
    if (timeFrame !== '') {
      setValue(timeFrame);
      const filteredDataset = datasets.filterBy.find(
        dataset => dataset.select === timeFrame
      );
      if (filteredDataset) {
        setDataset(filteredDataset);
        if (timeFrame === 'Harian') {
          const date = new Date();
          date.setDate(date.getDate() - 7);
          const dateNow = new Date();
          setTitle(date.toLocaleDateString('id-ID') + ' s/d ' + dateNow.toLocaleDateString('id-ID'));
        } else if (timeFrame === 'Bulanan') {
          const date = new Date();
          const firstDayCurrentMonth = getFirstDayOfMonth(
            date.getFullYear(),
            date.getMonth(),
          );
          const dateNow = new Date();
          setTitle(firstDayCurrentMonth.toLocaleDateString('id-ID') + ' s/d ' + dateNow.toLocaleDateString('id-ID'));
        } else {
          const dateNow = new Date();
          setTitle('1/1/' + dateNow.getFullYear() + ' s/d ' + dateNow.toLocaleDateString('id-ID'));
        }
      }
    }
  }, [timeFrame])

  const backgroundColor = [
    '#F6D7A7',
    '#259E86',
    '#87AAAA',
    '#F6EABE',
    '#C8E3D4',
    '#F2B227',
    '#5D965E',
  ];

  const data = {
    labels: dataset.data.map(item => item.label),
    datasets: [
      {
        label: 'Pie Chart',
        data: dataset.data.map(item => item.value),
        backgroundColor: backgroundColor,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        labels: {
          font: { family: 'Montserrat', size: '14' },
          color: '#000',
          boxWidth: 14,
        },
      },
      tooltip: {
        // enabled: false,
      },
      datalabels: {
        formatter: value => {
          return value;
        },
        color: '#000',
        font: { family: 'Montserrat', weight: '500', size: '12' },
        display: 'auto',
      },
      title: {
        display: true,
        text: title,
      }
    },
  };

  return (
    <Box
      h="full"
      bg="white"
      overflow="hidden"
      borderRadius="8px"
      border="1px solid"
      borderColor="dietela.primary"
    >
      <Box bg="dietela.primary" p="5">
        <Heading size="sm" color="white">
          {datasets.title}
        </Heading>
      </Box>
      <Box p="5">
        <Select
          className="timeframe-selector"
          value={value}
          onChange={handleChange}
          mb={10}
          width={'50%'}
          bg={'#F8F9FA'}
          variant={'filled'}
          border={'1px solid'}
          borderColor={'#DDDDDD'}
          _focus={{
            border: '2px solid',
            borderColor: 'dietela.primary',
            boxShadow: 'none',
          }}
        >
          {datasets.filter.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
        {datasets.question && (
          <Heading fontWeight={'400'} size={'sm'}>
            {datasets.question}
          </Heading>
        )}
        <Flex
          my="10"
          gap="10"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box w="250px">
            <Pie data={data} options={options} />
          </Box>
          <Flex flexDirection="column" gap="3">
            {dataset.data.map((item, index) => (
              <Flex key={index} gap="3">
                <Box minW="20px" h="20px" bg={backgroundColor[index]} />
                <Heading fontSize={'14px'} fontWeight="400">
                  {item.label} ({item.value})
                </Heading>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

export default PieChart;
