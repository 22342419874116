import React, { useState } from 'react';
import { Input } from '@chakra-ui/react';
import { useAsyncDebounce } from 'react-table';

export default function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);
  
  return (
    <Input
      type="text"
      placeholder="Pencarian"
      value={value || ''}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      w="15rem"
      bgColor="gray.100"
    />
  );
}