import {
  Center,
  CircularProgress,
  CircularProgressLabel,
  Heading,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

export default function StatusGraph({ props }) {
  function colorPercentage(category, percent) {
    if (category === 'Kondisi Personal') {
      if (percent <= 50) {
        return 'progress.red';
      }
      if (percent <= 89) {
        return 'progress.yellow';
      }
      return 'progress.green';
    }
    if (category === 'Pola Makan Sehat') {
      if (percent <= 57) {
        return 'progress.red';
      }
      if (percent <= 83) {
        return 'progress.yellow';
      }
      return 'progress.green';
    }
    if (category === 'Level Aktivitas Fisik') {
      if (percent <= 65) {
        return 'progress.red';
      }
      if (percent <= 85) {
        return 'progress.yellow';
      }
      return 'progress.green';
    }
    if (category === 'Kesiapan Hidup Sehat') {
      if (percent <= 35) {
        return 'progress.red';
      }
      if (percent <= 55) {
        return 'progress.yellow';
      }
      return 'progress.green';
    }
    if (category === 'Status Perilaku Makan') {
      if (percent <= 15) {
        return 'progress.green';
      }
      if (percent <= 30) {
        return 'progress.yellow';
      }
      return 'progress.red';
    }
    if (category === 'Pemahaman Gizi dan Hidup Sehat') {
      if (percent <= 55) {
        return 'progress.red';
      }
      if (percent <= 80) {
        return 'progress.yellow';
      }
      return 'progress.green';
    }
  }

  return (
    <Center columnGap="6" rowGap="8" flexWrap="wrap">
      {props.map(data => (
        <VStack key={data.id} w="150px" h="150px" spacing="3">
          <CircularProgress
            size="100px"
            value={data.percent}
            color={colorPercentage(data.category, Math.round(data.percent))}
            capIsRound
          >
            <CircularProgressLabel fontSize="xl" fontWeight="bold">
              {Math.round(data.percent)}%
            </CircularProgressLabel>
          </CircularProgress>
          <Heading textAlign="center" fontSize="14" fontWeight="semibold">
            {data.category}
          </Heading>
        </VStack>
      ))}
    </Center>
  );
}
