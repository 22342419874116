import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  headers: { 'Content-Type': 'application/json' },
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // return Promise.reject(error);
    if (error.response.data.message === 'token invalid') {
      localStorage.removeItem('token');
      localStorage.removeItem('id');
      localStorage.removeItem('access');
      localStorage.removeItem('exp');
      return window.location.reload();
    }
  }
);

export function authLogin(email, password) {
  return instance
    .post('/admin/auth', {
      email,
      password,
    })
    .then(res => {
      localStorage.setItem('token', res.data.data.token);
      localStorage.setItem('userId', res.data.data.id);
      localStorage.setItem('access', res.data.data.access);
      localStorage.setItem('exp', res.data.data.exp);
    });
}

export function getDashboardData() {
  const token = localStorage.getItem('token');
  return instance.get('/dashboard', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getAdmin() {
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');

  return instance.get(`/admin/profile/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getListAdmin() {
  const token = localStorage.getItem('token');

  return instance.get('/admin', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getListLayananDietela() {
  const token = localStorage.getItem('token');

  return instance.get('/layanan-dietela', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getDetailLayanan(id) {
  const token = localStorage.getItem('token');

  return instance.get(`/layanan-dietela/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function createAdmin(firstName, lastName, email) {
  const token = localStorage.getItem('token');

  const bodyRequest = {
    firstName,
    lastName,
    email,
  };

  return instance.post('/admin', bodyRequest, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getAdminAccount(id) {
  const token = localStorage.getItem('token');

  return instance.get(`/admin/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function updateAdmin(id, judul, isi, btnText, link) {
  const token = localStorage.getItem('token');

  const bodyRequest = {
    id,
    judul,
    isi,
    btnText,
    link,
  };

  return instance.put('/layanan-dietela', bodyRequest, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function deleteAdmin(id) {
  const token = localStorage.getItem('token');

  const bodyRequest = {
    id,
  };

  return instance.delete('/admin', {
    data: bodyRequest,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getListUser() {
  const token = localStorage.getItem('token');
  return instance.get('/table-user', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function deleteUser(id) {
  const token = localStorage.getItem('token');

  const bodyRequest = {
    id,
  };
  return instance.delete('/table-user', {
    data: bodyRequest,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getDetailUser(userId) {
  const token = localStorage.getItem('token');
  return instance.get(`/detail-user?user=${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getAnswerData(userId, part) {
  const token = localStorage.getItem('token');
  return instance.get(`/detail-answer?user=${userId}&part=${part}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getResultData(userId) {
  const token = localStorage.getItem('token');
  return instance.get(`/detail-result?user=${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getReporting({
  timerange , // weekly | monthly
  startdate,  // yyyy-mm-dd
  enddate,    // yyyy-mm-dd
  beratbadan, // 0 | 1
  kehamilan,  // 0 | 1
  penyakit,   // 0 | 1
  part1_7,    // 0 | 1
  result,     // 0 | 1
} = {}) {
  // /api/v1/report?timerange=weekly | monthly&startdate=yyyy-mm-dd&enddate=yyyy-mm-dd&beratbadan=0 | 1&kehamilan=0 | 1&penyakit=0 | 1&part1_7=0 | 1&result=0 | 1
  const token = localStorage.getItem('token');
  return instance.get('/report', {
    params: {
      timerange,
      startdate,
      enddate,
      beratbadan,
      kehamilan,
      penyakit,
      part1_7,
      result,
    },
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function forgotPassword(email) {
  return instance.put('/forgot-password', {
    email,
  });
}

export function changeProfile(
  username,
  firstname,
  lastname,
  email,
  old_password,
  new_password,
  password_confirm
) {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');

  const bodyRequest = {
    username,
    firstname,
    lastname,
    email,
    old_password,
    new_password,
    password_confirm,
  };

  return instance.put(`/admin/profile/${userId}`, bodyRequest, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
