import {
  Box,
  Center,
  CircularProgress,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  Textarea,
  Button,
} from '@chakra-ui/react';
import ContentLayout from 'components/layout/ContentLayout';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  getDetailLayanan,
  updateAdmin,
} from 'utils/axios.services';
import { setIsOpen } from 'reducer/modalReducer';
import ResponseDialog from 'components/common/ResponseDialog';
import ConfirmDialog from 'components/common/ConfirmDialog';
const he = require('he');

export default function EditLayananDietela() {
  const params = useParams();
  const layananId = params.layananId;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [responseType, setResponseType] = useState();

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: '',
      judul: '',
      isi: '',
      btnText: '',
      link: '',
    },
  });

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const { data: response } = await getDetailLayanan(layananId);

      setValue('id', response.data.id);
      setValue('judul', response.data.judul);
      setValue('isi', response.data.isi);
      setValue('btnText', response.data.btnText);
      setValue('link', he.decode(response.data.link));

      setIsLoading(false);
    }

    fetchData();
  }, [layananId, params.layananId, setValue]);

  async function onSubmit() {
    const data = getValues();
    await updateAdmin(data.id, data.judul, data.isi, data.btnText, data.link)
      .then(() => {
        navigate(`/manajemen-layanan-dietela/edit/${data.id}`);
        dispatch(setIsOpen(true));
        setResponseType('success');
      })
      .catch(() => {
        // toast.error(error.response.data.message);
        dispatch(setIsOpen(true));
        setResponseType('fail');
      });
  }

  async function handleBack() {
    const data = getValues();
    navigate(`/manajemen-layanan-dietela/detail/${data.id}`);
  }

  const routes = [
    {
      name: 'Manajemen Layanan Dietela',
      url: '/manajemen-layanan-dietela',
    },
    {
      name: 'Edit',
    },
  ];

  return (
    <ContentLayout routes={routes}>
      <Heading fontSize="28" mb="8">
        Edit Layanan Dietela
      </Heading>
      {isLoading ? (
        <Center>
          <CircularProgress isIndeterminate color="dietela.primary" />
        </Center>
      ) : (
        <Box
          bgColor="white"
          borderRadius="1rem"
          p="5"
          w={{
            base: 'full',
            lg: '600px',
          }}
        >
          <Flex as="form" flexDirection="column" gap="6">
            <FormControl isInvalid={errors.judul} isRequired>
              <FormLabel>Judul</FormLabel>
              <InputGroup>
                <Input
                  id="judul"
                  placeholder={'Masukkan Judul'}
                  {...register('judul', {
                    required: 'Nama Depan wajib diisi',
                  })}
                  color="dietela.primary"
                  fontFamily="roboto"
                  fontWeight="400px"
                  focusBorderColor="teal"
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.judul && errors.judul.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.isi} isRequired>
              <FormLabel>Isi</FormLabel>
              <InputGroup>
                <Textarea
                  id="isi"
                  placeholder="Masukkan Isi"
                  {...register('isi', {
                    required: 'Field wajib diisi',
                  })}
                  color="dietela.primary"
                  fontFamily="roboto"
                  fontWeight="400px"
                  focusBorderColor="teal"
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.isi && errors.isi.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.link} isRequired>
              <FormLabel>Tulisan di button</FormLabel>
              <InputGroup>
                <Input
                  id="btnText"
                  type="text"
                  placeholder="Masukkan Tulisan Button"
                  {...register('btnText', {
                    required: 'Button text wajib diisi',
                  })}
                  color="dietela.primary"
                  fontFamily="roboto"
                  fontWeight="400px"
                  focusBorderColor="teal"
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.link && errors.link.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.link} isRequired>
              <FormLabel>Button Link</FormLabel>
              <InputGroup>
                <Input
                  id="link"
                  type="text"
                  placeholder="Masukkan Link"
                  {...register('link', {
                    required: 'Link wajib diisi',
                  })}
                  color="dietela.primary"
                  fontFamily="roboto"
                  fontWeight="400px"
                  focusBorderColor="teal"
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.link && errors.link.message}
              </FormErrorMessage>
            </FormControl>
            <Flex
              rowGap="3"
              columnGap="5"
              mt="3"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                width={200}
                colorScheme="red"
                borderRadius="full"
                py={6}
                onClick={() => handleBack()}
              >Batal</Button>
              {responseType === 'success'
                ? (
                  <ResponseDialog
                    dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/berhasil_simpandata.png"
                    DialogText="Data berhasil diubah!"
                    BtnDialogText="Selesai"
                  />
                ) : (
                  <ResponseDialog
                    dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/gagal_simpandata.svg"
                    DialogText="Gagal menyimpan data"
                    BtnDialogText="Selesai"
                  />
                )}

              <ConfirmDialog
                dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/konfirmasi_simpan.png"
                ConfirmText="Anda yakin ingin mengubah data?"
                BtnConfirmText="Simpan"
                onConfirm={onSubmit}
                color="teal"
                width={200}
              />
            </Flex>
          </Flex>
        </Box>
      )}
    </ContentLayout>
  );
}
