import {
  Box,
  Button,
  Center,
  CircularProgress,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import ConfirmDialog from 'components/common/ConfirmDialog';
import NavigationBar from 'components/common/NavigationBar';
import ContentLayout from 'components/layout/ContentLayout';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { changeProfile, getAdmin } from 'utils/axios.services';
import { setIsOpen } from 'reducer/modalReducer';
import { useDispatch } from 'react-redux';
import ResponseDialog from 'components/common/ResponseDialog';
import { EmailIcon } from '@chakra-ui/icons';
import { FaIdCardAlt, FaKey } from 'react-icons/fa';

export default function EditProfile() {
  const [username, setUsername] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [responseType, setResponseType] = useState();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }, //, isSubmitting
  } = useForm({
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      old_password: '',
      new_password: '',
      password_confirm: '',
    },
  });

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const { data: response } = await getAdmin(userId);

      setValue('firstname', response.data.first_name);
      setValue('lastname', response.data.last_name);
      setValue('email', response.data.email);
      setUsername(response.data.username);

      setIsLoading(false);
    }

    fetchData();
  }, []);

  async function onSubmit() {
    const data = getValues();

    if (
      data.new_password !== '' &&
      data.new_password !== data.password_confirm
    ) {
      toast.error('Password confirm not match');
    } else {
      await changeProfile(
        username,
        data.firstname,
        data.lastname,
        data.email,
        data.old_password,
        data.new_password,
        data.password_confirm
      )
        .then(() => {
          navigate('/edit-profile');
          // localStorage.setItem('is_open', true);
          dispatch(setIsOpen(true));
          setResponseType('success');
        })
        .catch(() => {
          // toast.error(error.response.data.message);
          dispatch(setIsOpen(true));
          setResponseType('fail');
        });
    }
  }

  const routes = [
    {
      name: 'Dashboard',
      url: '/',
    },
    {
      name: 'Edit Profile',
    },
  ];

  return (
    <Box mb="8">
      <NavigationBar />
      <ContentLayout routes={routes} admin={true}>
        {isLoading ? (
          <Center>
            <CircularProgress isIndeterminate color="dietela.primary" />
          </Center>
        ) : (
          <Center>
            <Box w="560px">
              <Heading fontSize="28" mb="8">
                Admin Profile
              </Heading>
              <Flex
                as="form"
                flexDirection="column"
                gap="6"
                onSubmit={handleSubmit(onSubmit)}
              >
                <FormControl isInvalid={errors.firstname}>
                  <FormLabel>Nama Depan</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<FaIdCardAlt color="teal" />}
                    />
                    <Input
                      id="firstname"
                      placeholder={'Masukkan Nama Depan'}
                      {...register('firstname', {
                        required: 'Nama Depan wajib diisi',
                      })}
                      color="dietela.primary"
                      fontFamily="roboto"
                      fontWeight="400px"
                      focusBorderColor="teal"
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.firstname && errors.firstname.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.lastname}>
                  <FormLabel>Nama Belakang</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<FaIdCardAlt color="teal" />}
                    />
                    <Input
                      id="lastname"
                      placeholder="Masukkan Nama Belakang"
                      {...register('lastname', {
                        required: 'Nama Belakang wajib diisi',
                      })}
                      color="dietela.primary"
                      fontFamily="roboto"
                      fontWeight="400px"
                      focusBorderColor="teal"
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.lastname && errors.lastname.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.email}>
                  <FormLabel>Email</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<EmailIcon color="teal" />}
                    />
                    <Input
                      id="email"
                      type="email"
                      placeholder="Masukkan Email"
                      {...register('email', {
                        required: 'Email wajib diisi',
                      })}
                      color="dietela.primary"
                      fontFamily="roboto"
                      fontWeight="400px"
                      focusBorderColor="teal"
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel>Password Lama</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<FaKey color="teal" />}
                    />
                    <Input
                      id="old_password"
                      type="password"
                      placeholder={'Masukkan Password Lama'}
                      {...register('old_password', {
                        minLength: 3,
                      })}
                      color="dietela.primary"
                      fontFamily="roboto"
                      fontWeight="400px"
                      focusBorderColor="teal"
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Password Baru</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<FaKey color="teal" />}
                    />
                    <Input
                      id="new_password"
                      type="password"
                      placeholder={'Masukkan Password Baru'}
                      {...register('new_password', {
                        minLength: 5,
                      })}
                      color="dietela.primary"
                      fontFamily="roboto"
                      fontWeight="400px"
                      focusBorderColor="teal"
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Konfirmasi Password Baru</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<FaKey color="teal" />}
                    />
                    <Input
                      id="password_confirm"
                      type="password"
                      placeholder={'Masukkan Password Baru'}
                      {...register('password_confirm', {
                        required:
                          getValues().password_confirm !==
                          getValues().new_password,
                      })}
                      color="dietela.primary"
                      fontFamily="roboto"
                      fontWeight="400px"
                      focusBorderColor="teal"
                    />
                  </InputGroup>
                </FormControl>
                <Flex
                  rowGap="3"
                  columnGap="5"
                  mt="3"
                  flexDirection={{
                    base: 'column-reverse',
                    sm: 'row',
                  }}
                >
                  <Button
                    py="6"
                    w="full"
                    borderRadius="full"
                    colorScheme="gray"
                    onClick={() => navigate('/')}
                  >
                    Batal
                  </Button>

                  { responseType === 'success'
                    ? (
                      <ResponseDialog
                        dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/berhasil_simpandata.png"
                        DialogText="Data berhasil diubah!"
                        BtnDialogText="Selesai"
                      />
                    ) : (
                      <ResponseDialog
                        dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/gagal_simpandata.svg"
                        DialogText="Gagal menyimpan data"
                        BtnDialogText="Selesai"
                      />
                    )}

                  <ConfirmDialog
                    dialogImageUrl="https://nama-storage.ap-south-1.linodeobjects.com/konfirmasi_simpan.png"
                    ConfirmText="Anda yakin ingin mengubah data profil anda??"
                    BtnConfirmText="Simpan"
                    onConfirm={onSubmit}
                    color="teal"
                  />
                </Flex>
              </Flex>
            </Box>
          </Center>
        )}
      </ContentLayout>
    </Box>
  );
}
