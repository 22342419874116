import React from 'react';
import { useSelector } from 'react-redux';
import { setIsOpen } from 'reducer/modalReducer';
import { useDispatch } from 'react-redux';
import {
  Button,
  Center,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react';

function ResponseDialog({ dialogImageUrl,  DialogText, BtnDialogText }) {
  const { onClose } = useDisclosure();
  const dispatch = useDispatch();
  const finalRef = React.useRef(null);
  const isOpen = useSelector((state) => state.modal.isOpen);

  function handleClick() {
    dispatch(setIsOpen());
  }

  return (
    <>
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent pt="10" pb="5" px="5" mx="4">
          <ModalBody as={Center} flexDirection="column" mb="3">
            <Image src={dialogImageUrl} mb="6" />
            <Text fontSize="lg" textAlign="center">
              {DialogText}
            </Text>
          </ModalBody>
          <ModalFooter
            as={Flex}
            rowGap="3"
            columnGap="5"
            mt="3"
            flexDirection={{
              base: 'column-reverse',
              sm: 'row',
            }}
          >
            <Button
              py="6"
              w="full"
              borderRadius="full"
              colorScheme="teal"
              onClick={() => { handleClick(); }}
            >
              {BtnDialogText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ResponseDialog;