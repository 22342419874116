import React, { createContext, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  './styles/global.css'

import theme from 'styles/theme';
import SidebarLayout from './components/layout/SidebarLayout';
import AuthLayout from 'components/layout/AuthLayout';
import Login from 'components/auth/Login';
import ForgotPassword from 'components/auth/ForgotPassword';
import ManajemenUser from 'views/ManajemenUser';
import DetailUser from 'views/ManajemenUser/DetailUser';
import DetailJawaban from 'views/ManajemenUser/DetailJawaban';
import DetailResult from 'views/ManajemenUser/DetailResult';
import Dashboard from 'views/Dashboard';
import ManajemenAdmin from 'views/ManajemenAdmin';
import TambahAdmin from 'views/ManajemenAdmin/TambahAdmin';
import EditAdmin from 'views/ManajemenAdmin/EditAdmin';
import EditProfile from 'views/EditProfile';
import ManajemenLayananDietela from 'views/ManajemenLayananDietela';
import DetailLayananDietela from 'views/ManajemenLayananDietela/DetailLayananDietela';
import EditLayananDietela from 'views/ManajemenLayananDietela/EditLayananDietela';

export const UserContext = createContext();

function App() {
  const [user, setUser] = useState();

  return (
    <ChakraProvider theme={theme}>
      <UserContext.Provider value={{ user, setUser }}>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route element={<SidebarLayout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/manajemen-assesment"
                element={<p>Manajemen Assesment</p>}
              />
              <Route path="/manajemen-user" element={<ManajemenUser />} />
              <Route
                path="/manajemen-user/detail-user/:userId"
                element={<DetailUser />}
              />
              <Route
                path="/manajemen-user/detail-user/:userId/detail-jawaban/:jawabanId"
                element={<DetailJawaban />}
              />
              <Route
                path="/manajemen-user/detail-user/:userId/detail-result"
                element={<DetailResult />}
              />
              <Route path="/manajemen-admin" element={<ManajemenAdmin />} />
              <Route
                path="/manajemen-admin/tambah-admin"
                element={<TambahAdmin />}
              />
              <Route
                path="/manajemen-admin/edit-admin/:adminId"
                element={<EditAdmin />}
              />
              <Route
                path="/manajemen-layanan-dietela"
                element={<ManajemenLayananDietela />}
              />
              <Route
                path="/manajemen-layanan-dietela/detail/:layananId"
                element={<DetailLayananDietela />}
              />
              <Route
                path="/manajemen-layanan-dietela/edit/:layananId"
                element={<EditLayananDietela />}
              />
            </Route>
            <Route path="/edit-profile" element={<EditProfile />} />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>
        </Routes>
        <ToastContainer />
      </UserContext.Provider>
    </ChakraProvider>
  );
}

function PrivateRoute() {
  if (!localStorage.getItem('token')) {
    return <Navigate to="/login" />;
  } else if (localStorage.getItem('exp') < Math.floor(Date.now() / 1000)) {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('access');
    localStorage.removeItem('exp');
    return <Navigate to="/login" />;
  }
  return <Outlet />;
}

export default App;
