import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function getPart(url) {
  const params = new URLSearchParams(url);
  return params.get('part');
}

function createUrlAnswer(part, id) {
  return `/manajemen-user/detail-user/${id}/detail-jawaban/${part}`;
}

export default function CardResult({ id, data }) {
  return data.title ? (
    <Box>
      <Heading fontSize="18" mb="5">
        {data.title}
      </Heading>
      {data.cards ? (
        <Flex gap="10" flexWrap="wrap">
          {data.cards.map(card => (
            <Box key={card.name} w={{ base: '100%', sm: '370px' }}>
              <CardWithDetail id={id} data={card} />
            </Box>
          ))}
        </Flex>
      ) : (
        <CardWithDetail  id={id} data={data.card} />
      )}
    </Box>
  ) : (
    <CardWithoutDetail id={id} data={data.card} />
  );
}

const CardWithDetail = ({ id, data }) => {
  const navigate = useNavigate();

  return (
    <Flex
      bg="white"
      borderRadius="12"
      justifyContent="space-between"
      alignItems="center"
      p="5"
      gap="5"
      minH="140px"
      w={{ base: '100%', sm: '370px' }}
    >
      <Flex flexDirection="column" gap="2">
        {data.session && (
          <Text fontSize="lg" fontWeight="500" color="dietela.primary">
            {data.session}
          </Text>
        )}
        {data.name && (
          <Text fontSize="lg" fontWeight="500" color="dietela.primary">
            {data.name}
          </Text>
        )}
        <Text fontSize="lg" fontWeight="bold">
          Score : {data.score}
        </Text>
        <Text>{data.interpretasi}</Text>
      </Flex>
      <Button
        px="6"
        minW="86px"
        variant="outline"
        colorScheme="teal"
        borderRadius="full"
        onClick={() => navigate(createUrlAnswer(getPart(data.link), id))}
      >
        Lihat
      </Button>
    </Flex>
  );
};

const CardWithoutDetail = ({ id, data }) => {
  const navigate = useNavigate();

  return (
    <Flex
      bg="white"
      borderRadius="12"
      justifyContent="space-between"
      alignItems="center"
      p="5"
      gap="5"
      minH="140px"
      w={{ base: '100%', sm: '370px' }}
    >
      <Heading fontSize="18">{data.title}</Heading>
      <Button
        px="6"
        variant="outline"
        colorScheme="teal"
        borderRadius="full"
        onClick={() => navigate(createUrlAnswer(getPart(data.link), id))}
      >
        Lihat
      </Button>
    </Flex>
  );
};
